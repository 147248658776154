.sidebar-right #frame .areaBody .areaFrame .listFrame .frame{
    width: 85px;
    height: 85px;
    background: transparent;
}
.sidebar-right #frame .areaBody .areaFrame .listFrame .frame img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.areaDesignCanvas{
    min-height: 100vh;
}
.areaDesignCanvas .canvas-container{
    /* margin: 0 auto; */
}
#frame .boxUpload{
    display: flex;
}
#frame .boxUpload .frame-bg-image {
    position: relative;
    width: 125px;
    height: 110px;
    margin: 20px 0;
}
#frame .boxUpload .frame-bg-image img{
    width: 100%;
    height: 100%;
}
#frame .boxUpload .frame-bg-image .delete-bg-frame{
    position: absolute;
    background: #FFD702;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
    color: #FFFFFF;
    top: -8px;
    right: -8px;
}
#frame .boxUpload .frame-bg-image .delete-bg-frame:hover{
    background: #FFFFFF;
    color: #FFD702;
}